<template>
  <div class="common_sub_container">
    <h2 class="sections_to_hide">KWM POSTAL ITEMS BILLING REPORT</h2>
    <section class="sections_to_hide">
      <div class="form_section1">
        <table>
          <tr>
            <td style="width:30%">
              <div class="label_and_element_wrapper">
                <label>
                  Choose Period
                  <span></span>
                </label>
                <flat-pickr
                  class="date_for_pos_fixed"
                  v-model="daterange"
                  :config="configDate"
                  placeholder="Date"
                  name="birthdate"
                  @on-close="dateChanged">
                  </flat-pickr>
              </div>
            </td>
          </tr>
          <tr>
          </tr>
          <tr>
          </tr>
          <tr>
            <td>
              <a class="link_bt bt_save" @click="fetchReport">Search</a>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </section>
    <section v-if="show_data" class="reports_to_print">
      <div class="despatch_summary_wrapper" v-if="Object.keys(reports).length > 0">
        <header>
          <h3>KWM Postal Items Billing Report {{ auth().user_type != 'super_admin' ? ` - ${auth().branch}` : '' }}</h3>
          <div class="right_part">
            <a title="Download" ref="download" @click="downloadReport">
              <i class="material-icons">arrow_drop_down_circle</i>
            </a>
            <a title="Print" @click="printRecord">
              <i class="material-icons">print</i>
            </a>
          </div>
        </header>
        <div class="despatch_summary_table_container">
          <table>
            <thead>
              <tr>
                <td><b>Month Invoice Is Raised</b></td>
                <td><b>Payee Number</b></td>
                <td><b>Currency</b></td>
                <td><b>Total Amount</b></td>
                <td></td>
                <td><b>Issued Month</b></td>
                <td style="padding: 35px;"><b>Matter Number</b></td>
                <td><b>Codecat</b></td>
                <td><b>Date Issued</b></td>
                <td style="padding: 35px;"><b>Item / Date / Requestor</b></td>
                <td></td>
                <td style="padding: 35px;"><b>GL Code</b></td>
                <td><b>GST</b></td>
                <td><b>GST Code</b></td>
                <td><b>Value Except GST</b></td>
              </tr>
            </thead>
            <tbody>
              <template v-for="data in reports">
                <template>
                  <tr>
                    <td>
                      {{data.issue_by_date | nextMonthDate}}  
                      <!-- Month Invoice is raised -->
                    </td>
                    <td>
                      10018101-010
                      <!-- Payee Number -->
                    </td>
                    <td>
                      AUD
                      <!-- Currency -->
                    </td>
                    <td>
                      {{ totalAmount.toFixed(2) }}
                      <!-- Total Amount -->
                    </td>
                    <td>
                      
                      <!-- Blank 1 -->
                    </td>
                    <td>
                      FXAP{{data.issue_by_date | yearMonth}}E1
                      <!-- Issued month -->
                    </td>
                    <td>
                      {{ data.matter_number ? data.matter_number.matter_number : '' }}
                      <!-- Matter Code -->
                    </td>
                    <td>
                      POS
                      <!-- Codecat -->
                    </td>
                    <td>
                      {{data.issue_by_date | formatDate}}
                      <!-- Date issued -->
                    </td>
                    <td>
                      {{ data.postal_document_type ? data.postal_document_type.postal_document_type : ''}}   {{data.issue_by_date | formatDate}}   {{ data.requester_name }}
                      <!-- Item / date / requestor -->
                    </td>
                    <td>
                      <!-- Blank 2 -->
                    </td>
                    <td>
                      {{ (data.custom_code_value && data.custom_code == 'GL Code') ? glcodeGenerate(data.custom_code_value) : data.custom_code_value }}
                      <!-- GL Code -->
                    </td>
                    <td>
                      {{ (data.total_price / 11).toFixed(2) }}
                      <!-- GST -->
                    </td>
                    <td>
                      AUGSTINPUT
                      <!-- GST code -->
                    </td>
                    <td>
                      {{ (data.total_price - (data.total_price / 11)).toFixed(2)}}
                      <!-- Value Except GST -->
                    </td>
                    
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <h4 v-if="Object.keys(reports).length == 0">No data found</h4>
    </section>

    <!-- <div class="custom-btn-wrapper" style="padding: 35px;">
      <ActionButton @handle-click="downloadReport"
        v-if="Object.keys(reports).length > 0" triggerType="button" type="system"
        :className="[]" :isDisabled="button.disabled">
        {{ generate_report_button.text.toUpperCase() }}
      </ActionButton>
    </div>
    <div ref="download" style="display: none;"></div> -->
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
import ActionButton from "../components/ActionButton.vue";

export default {
  name: "ReportsDelivery",
  components: {
    flatPickr,
    ActionButton,
  },
  data() {
    return {
      daterange: [
          moment(new Date()).format("YYYY-MM-01"),
      ],
      date: null,
      enableTime: true,
      reports: [],
      post_data: {
        from: moment(new Date()).format("YYYY-MM-01"),
        to: moment(new Date()).format("YYYY-MM-") + moment().daysInMonth(),
        via:"issue_by_date",
      },
      api_url: "",
      configDate: {
          mode: "single",
          format: "d-m-Y",
          altFormat: "M-Y",
          altInput: true
      },
      show_data: false,
      // button: {
      //   disabled: false
      // },
      // generate_report_button: {
      //   text: 'Generate KWM Billing Report',
      //   disabled: false
      // },
    };
  },
  async created() {
    this.api_url = process.env.VUE_APP_API_URL;
    this.$emit("breadCrumbData", ["Reports", " KWM Postal Items Billing Report"]);
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('DD/MM/YYYY')
    },
    nextMonthDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).add(1, 'M').format('YYYYMM') + '01';
    },
    yearMonth(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYYMM')
    },
  },

  computed: {
    totalAmount() {
      if (!this.reports || this.reports.length === 0) return 0;

      return this.reports
        .reduce((sum, item) => sum + (item.unit_price * item.issue_qty), 0);
    }
  },

  methods: {
    dateChanged(daterange) {
        this.post_data.from = moment(daterange[0]).format("YYYY-MM-01");
        this.post_data.to = moment(daterange[0]).endOf('month').format("YYYY-MM-DD");
    },

    glcodeGenerate(dataGlCode) {
      // let glcode = '21100-000000-'

      // glcode += "21100-000000-"
      // glcode += dataGlCode.section + "-"
      // glcode += dataGlCode.department + "-"
      // glcode += dataGlCode.office + "-"
      // glcode += dataGlCode.entity + "-"
      // glcode += "000"

      // return glcode

      return `21100-000000-${dataGlCode}`;
    },
    
    fetchReport() {
      this.axios
        .post("/api/reports/get-kwm-billing-report", this.post_data)
        .then(response => {
          console.log(response.data.data);
          this.show_data = true;
          this.reports = response.data.data;
        })
        .catch(error => {
          console.log(e);
        });
    },
    downloadReport() {
      // try {
        let method = "POST";
        let input;
        let form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute(
          "action",
          this.api_url +
            "/api/reports/get-kwm-billing-report/download?token=" +
            localStorage.token
        );
        Object.keys(this.post_data).forEach(item => {
          input = document.createElement("input");
          input.type = "text";
          input.name = item;
          input.value = this.post_data[item];
          form.appendChild(input);
        });

        // this.generate_report_button.disabled = true;

        this.$refs.download.appendChild(form);
        form.submit();
        this.$refs.download.removeChild(form);

      // } catch (error) {
      //   console.error("An error occurred while generating the approval file:", error);
      //   this.generate_report_button.disabled = false;
      // } finally {
      //   this.generate_report_button.disabled = false;
      // }
    },
    printRecord() {
      window.print();
    }
  }
};
</script>
<style>
.flatpickr-calendar.lol {
  border: 5px solid red;
}
</style>
<style scoped  >
.scoped_cell_width_adj td {
  width: 25%;
}
.link_bt {
  margin-left: 10px;
}
.despatch_summary_wrapper header {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.despatch_summary_wrapper header h3 {
  font-size: 20px;
  font-weight: 600;
}
.despatch_summary_wrapper header .right_part {
  width: auto;
  height: 20px;
}
.despatch_summary_wrapper header .right_part a {
  display: inline-block;
  margin-right: 20px;
}
.despatch_summary_wrapper header .right_part a i {
  font-size: 32px;
}
.despatch_summary_wrapper {
  padding: 20px;
  background: #efefef;
}
.despatch_summary_table_container {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
  min-width: 800px;
  overflow-x: auto;
}
.despatch_summary_table_container table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
}
.despatch_summary_table_container table td {
  padding: 10px;
  border: 1px solid #ddd;
}
</style>
<style scoped>

.custom-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-btn-wrapper .custom-action-button {
  padding-inline: 2rem;
  margin-right: 10px;
}

.custom-btn-wrapper .custom-action-button:last-child {
  margin-right: 0 !important;
}

@media print {
  .sections_to_hide {
    display: none;
  }
  .reports_to_print {
    display: block;
  }
  .despatch_summary_wrapper {
    padding: 0;
    background: none;
  }
  .despatch_summary_wrapper header .right_part {
    display: none;
  }
  .despatch_summary_table_container {
    padding: 0;
    border: 1px solid #999;
  }
  .despatch_summary_table_container h2 {
    font-size: 16px;
  }
  .despatch_summary_table_container table td {
    border-color: #999;
    font-size: 10px;
  }
  @page {
    size: landscape;
  }
}
</style>